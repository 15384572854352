<template>
    <div class="coupon">
        <h1>Redeem your voucher!
        </h1>
        <div v-if="barcode">
            <vue-barcode
                :value="barcode"
                format="EAN8"
                width="3"
                font-size="30"
            >
                Ooops something went wrong......
            </vue-barcode>
        </div>
    </div>
</template>

<script>
    import VueBarcode from 'vue-barcode'

    export default {
        name: 'Mission',
        components: { VueBarcode },
        data() {
            return {
                barcode: null
            }
        },
        created() {
            const code = this.$route.params.code
            if (code) {
                this.barcode = Buffer.from(code, 'base64').toString()
            }
        }
    }
</script>

<style scoped>
    .coupon {
        max-width: 420px;
        padding: 30px 38px 2rem;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid var(--white);
        background-color: var(--login-forms-background);
        color: var(--white);
        margin-top: 5vh;
    }
</style>
